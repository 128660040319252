function Contact() {
    return (
      <div class=' min-h-screen flex justify-between mt-[30%] overflow-hidden'>
        <div className=" lg:ml-[9%] lg:visible invisible">
         <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc2C2FpqMkfMmUo4-4nJIavsvDy4JCWYbxc21-Z3frtoq0w0Q/viewform?embedded=true" width="640" height="850" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
        <div className=" absolute lg:ml-[9%] lg:invisible">
         <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc2C2FpqMkfMmUo4-4nJIavsvDy4JCWYbxc21-Z3frtoq0w0Q/viewform?embedded=true" width="400" height="900" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
        <div className=' flex'>
          <div className=' h-fit text-9xl mt-[30%] ml-[1%] [writing-mode:vertical-rl] rotate-180 invisible lg:visible'>
            Contact Us
          </div>
          <svg width="330" height="690" xmlns="http://www.w3.org/2000/svg" className=' mt-[21%] invisible lg:visible'>
            <rect width="330" height="690" fill="#1B1B1B"/>
          </svg>
        </div>
      </div>
      );
  } 
  
  export default Contact;
  