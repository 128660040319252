import './dividor.css'
import img from './assets/Web-developer.svg'
import sal from './assets/Saly.png'
function App() {
  return (
    <div className=' min-h-screen flex overflow-hidden'>
      <img src={sal} className='absolute mt-[18%] ml-[1%] invisible lg:visible '/>
      <svg width="330" height="690" xmlns="http://www.w3.org/2000/svg" className=' mt-[6%] invisible lg:visible'>
        <rect width="330" height="690" fill="#1B1B1B"/>
      </svg>
      <div className=' h-fit text-9xl mt-[10%] ml-[1%] [writing-mode:vertical-lr] invisible lg:visible'>
        About Us
      </div>
      <div className="mt-[3%] max-w-3xl md:p-12 lg:ml-[54%] absolute">
        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between text-left">
              <div className="flex-col text-left my-0 justify-end w-[99%] 2 px-8" >
                  
                  <p className="lg:text-3xl text-2xl text-blue-950 font-bold">Pinaki Infotech is the best name in Corporate IT Rental since 1997, providing businesses with the latest technology at competitive prices.</p>
                  <div>
                      <p className='my-3 lg:text-2xl sm:text-xl text-gray-600 font-semibold'>Our core competency is in rental of laptops & so unlike others we are not involved in any other business vertical, not even in sales.</p>
                  </div>
                  <div>
                      <p className='my-3 text-xl text-gray-600 font-semibold'><br/>We are dedicated to delivering unparalleled service, marked by quick delivery, quality product, and comprehensive customer support PAN India. Our approach is fundamentally aligned with our corporate ethos of enhancing business efficiency through catering personalized needs.</p>
                  </div>
                  <svg width="519" height="23" className=' mt-[10%] w-[99%] lg:visible invisible' viewBox="0 0 519 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="519" height="23" fill="#C4C4C4"/>
                  </svg>
                  <svg width="519" height="87" viewBox="0 0 519 87" fill="none" xmlns="http://www.w3.org/2000/svg" className=' mt-[6%] w-[99%] lg:visible invisible'>
                    <rect width="519" height="87" fill="#C4C4C4"/>
                  </svg>
              </div>
          </div>
        </div>
    </div>
  );
}

export default App;
