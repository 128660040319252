import {
    FigmaLogoIcon,
    FramerLogoIcon,
    SketchLogoIcon,
    TwitterLogoIcon,
    GitHubLogoIcon,
    VercelLogoIcon,
    NotionLogoIcon,
    DiscordLogoIcon,
    InstagramLogoIcon,
    LinkedInLogoIcon,
  } from "@radix-ui/react-icons";
import icici from './assets/icici.jfif';
import cococola from './assets/cococola.jpg';
import cdac from './assets/cdac.jpg';
import motherson from './assets/motherson.jpeg';
import titan from './assets/titan.png';
import idp from './assets/idp.png';
import itc from './assets/itc.png';
import fitbit from './assets/fitbit.png';
import republic from './assets/republic.png';
import sandisk from './assets/sandisk.png';
  const LOGOS = [
    <img src={icici} width={90} height={90} className="text-slate-800" />,
    <img src={cococola} width={90} height={90} className="text-slate-800" />,
    <img src={cdac} width={200} height={100} className="text-slate-800" />,
    <img src={motherson} width={99} height={99} className="text-slate-800" />,
    <img src={titan} width={99} height={99} className="text-slate-800" />,
    <img src={sandisk} width={180} height={100} className="text-slate-800" />,
    <img src={itc} width={69} height={69} className="text-slate-800" />,
    <img src={fitbit} width={200} height={100} className="text-slate-800" />,
    <img src={idp} width={120} height={120} className="text-slate-800" />,
  ];
  
  export const InfiniteSlider = () => {
    return (
      <div className="relative lg:m-auto lg:overflow-hidden before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] lg:before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_0%)] after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(250px*10)]">
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[180px] items-center justify-center"
              key={index}
            >
              {logo}
            </div>
          ))}
          {LOGOS.map((logo, index) => (
            <div className="slide flex w-[180px] items-center justify-center" key={index}>
              {logo}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default InfiniteSlider;