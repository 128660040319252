import InfiniteSlider from "./InfiniteSlider";
import punch from './assets/final123.png'
import button from './assets/button.png'
function App() {
    return (
      <div className=" min-h-screen overflow-hidden">
        <div className="flex">
          <div className=" lg:mt-[15%] mt-[30%] lg:ml-[6%] ml-[3%] lg:w-[60%] w-[90%] ">
              <div>
              <a className="lg:text-2xl text-lg">Your ONE STOP SHOP for all your IT needs<br/></a>
              <a className="lg:text-5xl text-5xl">Renting you can TRUST</a>
              {/* <a className="text-5xl underline underline-offset-8">Renting you can TRUST</a> */}
              </div>
              <div className="mt-[16%] flex justify-between">
              <div className=" lg:mt-[0%] mt-[30%]"><a className="lg:text-3xl text-xl font-bold">27</a><a className="lg:text-2xl text-lg">yrs of Experience & Expertise</a><br/><a className="lg:text-2xl text-xl">Clients we have served</a></div>
              </div>
              <div className=" lg:mt-[2%] mt-[6%] w-full overflow-hidden">
                <InfiniteSlider/>
              </div>
          </div>
          <div className=" ml-[58%] absolute invisible lg:visible">
              <img src={punch} className=' scale-90  '/>
          </div>
        </div>
        <div className="flex justify-center lg:mt-[2%] mt-[12%]">
          <button onClick={() => window.scrollTo({ top: 960, behavior: "smooth" })}><img src={button} className='animate-bounce w-20 h-20'/></button>
        </div>
      </div>
    );
  }
  
  export default App;
  