import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navbar from './Navbar';
import ModelViewer from './ModelViewer';
import reportWebVitals from './reportWebVitals';
import './dividor.css'
import Landing from './Landing.js';
import Why from './why';
import Rental from './Rental';
import Contact from './Contact.js';
import { Helmet } from 'react-helmet';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" name="Pinaki Infotech" content="width=device-width, initial-scale=1.0"/>
      <title>Pinaki Infotech</title>
    </Helmet>
    <Navbar />
    <Landing/>
    <App/>
    <Why/>
    {/* <Rental/> */}
    <Contact/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//F:\Code\WebD\pinaki-infotech-react\root\src