import { useState, useEffect } from "react";

// assets

const Navbar = () => {
  // use theme from local storage if available or set light theme
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );

  // update state on toggle
  const handleToggle = (e) => {
    if (e.target.checked) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  // set theme state in localstorage on mount & also update localstorage on state change
  useEffect(() => {
    localStorage.setItem("theme", theme);
    const localTheme = localStorage.getItem("theme");
    // add custom data-theme attribute to html tag required to update theme using DaisyUI
    document.querySelector("html").setAttribute("data-theme", localTheme);
  }, [theme]);
    return (
        <div className="navbar bg-base-100">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <svg  className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
            </label>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
              <li onClick={() => window.scrollTo({ top: 1042, behavior: "smooth" })}><a>About</a></li>
              <li onClick={() => window.scrollTo({ top: 2042, behavior: "smooth" })}><a>Why Us</a></li>
              <li onClick={() => window.scrollTo({ top: 3100, behavior: "smooth" })}><a>Contact Us</a></li>
            </ul>
          </div>
          <a className="btn btn-ghost normal-case text-2xl">Pinaki Infotech</a>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            <li onClick={() => window.scrollTo({ top: 1042, behavior: "smooth" })}><a>About</a></li>
            <li onClick={() => window.scrollTo({ top: 2042, behavior: "smooth" })}><a>Why Us</a></li>
            <li tabIndex={0}>
              <details>
                <summary>Rental</summary>
                <ul className="p-2">
                  <li><a>Industry Standard Laptops</a></li>
                  <li><a>Graphic Rentals</a></li>
                  <li><a>Executive Laptops</a></li>
                </ul>
              </details>
            </li>
            <li onClick={() => window.scrollTo({ top: 3100, behavior: "smooth" })}><a>Contact Us</a></li>
            <li className=" animate-bounce"><a href="https://buyalaptop.in">Buy old laptops</a></li>
          </ul>
        </div>
        <div className="navbar-end">
          <a className="btn btn-ghost">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" /></svg>
          </a>
        </div>
      </div>
    );
};
export default Navbar;

  